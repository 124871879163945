<template>
  <nav class="admin-navbar">
    <!-- Botón de menú hamburguesa -->
    <button class="menu-toggle" @click="toggleMenu">
      <span class="hamburger-icon"></span>
    </button>

    <!-- Menú de navegación -->
    <div :class="['nav-links', { open: isMenuOpen }]">
      <router-link to="/admin/dashboard" @click="closeMenu">Dashboard</router-link>
      <router-link to="/admin/add-product" @click="closeMenu">Agregar Producto</router-link>
      <router-link to="/admin/edit-products" @click="closeMenu">Editar Productos</router-link>
      <router-link to="/admin/sales" @click="closeMenu">Ventas</router-link>
      <router-link to="/admin/agenda" @click="closeMenu">Agenda</router-link>
      <router-link to="/admin/venta/sesiones" @click="closeMenu">Vender</router-link>
      <router-link to="/admin/search-client" @click="closeMenu">Clientes</router-link>
      <button @click="logout" class="logout-btn">Cerrar Sesión</button>
    </div>
  </nav>
</template>

<script>
import apiClient from "../api";

export default {
  name: "AdminNavbar",
  data() {
    return {
      isMenuOpen: false, // Controla si el menú está abierto o cerrado
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    async logout() {
      try {
        await apiClient.get("/logout");
        this.$router.push("/admin");
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
      }
    },
  },
};
</script>

<style scoped>
.admin-navbar {
  background-color: #007BFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Botón de menú hamburguesa */
.menu-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  display: none;
  /* Oculto en pantallas grandes */
}

.hamburger-icon {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
}

/* Enlaces de navegación */
.nav-links {
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
}

.nav-links a:hover {
  text-decoration: underline;
}

.logout-btn {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

.logout-btn:hover {
  text-decoration: underline;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    /* Mostrar botón hamburguesa en móviles */
  }

  .nav-links {
    display: none;
    /* Ocultar menú por defecto */
    position: absolute;
    top: 60px;
    /* Debajo del navbar */
    left: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: #007BFF;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex;
    /* Mostrar menú cuando está abierto */
  }

  .nav-links a {
    margin: 10px 0;
    /* Separación entre los enlaces en móviles */
    font-size: 1.1em;
  }
}
</style>
