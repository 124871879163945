<template>
  <div class="pack-container">
    <Navbar />
    <Sesiones @agregar-al-carrito="agregarAlCarrito" />

    <!-- Información expandible -->
    <div class="additional-info">
      <div class="details-section">
        <summary @click="toggleSection('como-funciona')" :class="{ open: openSection === 'como-funciona' }">
          Cómo Funciona
        </summary>
        <div v-if="openSection === 'como-funciona'">
          <p>
            La Fototerapia con luz de Colágeno disminuye visiblemente las
            líneas finas y las arrugas, y conduce a la lucha contra el
            envejecimiento visible. La renovación del colágeno y la elastina
            de producción celular natural hace que las líneas finas en la piel
            y las arrugas desaparezcan y sean menos visibles al estar la piel
            más flexible y tersa.
          </p>
        </div>
      </div>
      <div class="details-section">
        <summary @click="toggleSection('mas-detalles')" :class="{ open: openSection === 'mas-detalles' }">
          Más Detalles
        </summary>
        <div v-if="openSection === 'mas-detalles'">
          <p>
            Sesiones disponibles para cualquier persona mayor de 18 años.
            Consulta nuestras políticas de seguridad para un bronceado
            saludable.
          </p>
        </div>
      </div>
      <div class="details-section">
        <summary @click="toggleSection('beneficios')" :class="{ open: openSection === 'beneficios' }">
          Beneficios
        </summary>
        <div v-if="openSection === 'beneficios'">
          <ul>
            <li>Bronceado uniforme</li>
            <li>Mejora la elasticidad de la piel</li>
            <li>Reduce las líneas de expresión</li>
            <li>Aporta vitamina D</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";
import Sesiones from "../components/Sesiones.vue";
import { ref, onMounted } from "vue";
import apiClient from "../api"; // Asegúrate de tener tu cliente de API configurado
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    Navbar,
    Footer,
    Sesiones,
  },
  setup() {
    const packs = ref([]); // Packs obtenidos de la API
    const selectedPack = ref(null); // Paquete seleccionado inicializado como null
    const priceRange = ref(""); // Rango de precios
    const openSection = ref(""); // Para manejar la sección expandida

    const store = useStore();
    const router = useRouter();

    // Método para comprar ahora
    const buyNow = () => {
      if (!selectedPack.value || !selectedPack.value.id) {
        alert("Por favor selecciona un pack de sesiones");
        return;
      }

      // Añadir la sesión seleccionada al carrito
      store.commit("agregarAlCarrito", {
        id: selectedPack.value.id,
        nombre: selectedPack.value.nombre,
        precio: selectedPack.value.precio,
        cantidad: 1,
        imagen: selectedPack.value.imagen,
      });

      // Redirigir al carrito o checkout
      router.push("/checkout");
    };

    // Seleccionar un pack
    const selectPack = (pack) => {
      selectedPack.value = pack;
    };

    const getHardcodedPrice = (packId) => {
      const hardcodedPrices = {
        28: 79000, // Precio normal para el pack de 10
        26: 45000, // Precio normal para el pack de 5
        29: 9000, // Precio normal para el pack de 1
        39: 105000, // Precio normal para el pack de 15
      };
      return hardcodedPrices[packId] || 0; // Si no encuentra el id, devuelve 0
    };

    // Formatear el precio
    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    // Cargar packs de la API al montar el componente
    onMounted(async () => {
      try {
        const response = await apiClient.get("/productos/sesiones");
        packs.value = response.data;

        // Ordenar los packs por el nombre o el ID
        const order = ["5", "10", "15", "1"];

        packs.value.sort((a, b) => {
          return order.indexOf(a.descripcion) - order.indexOf(b.descripcion);
        });

        // Inicializar selectedPack con el primer pack si está disponible
        if (packs.value.length > 0) {
          selectedPack.value = packs.value[0];
        }

        // Calcular el rango de precios
        const precios = packs.value.map((pack) => pack.precio);
        const minPrice = Math.min(...precios);
        const maxPrice = Math.max(...precios);
        priceRange.value = `${formatPrice(minPrice)} - ${formatPrice(
          maxPrice
        )}`;
      } catch (error) {
        console.error("Error al obtener los packs de sesiones:", error);
      }
    });

    // Método para manejar la expansión de secciones
    const toggleSection = (section) => {
      openSection.value = openSection.value === section ? "" : section;
    };

    return {
      packs,
      selectedPack,
      priceRange,
      buyNow,
      selectPack,
      formatPrice,
      toggleSection,
      openSection,
      getHardcodedPrice,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.pack-container {
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 11em;
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2em;
}

.banner {
  width: 100%;
  background-color: #ffdb58;
  color: #333;
  padding: 10px 0;
  text-align: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 1000;
  margin-top: 6em;
}

.banner p {
  margin: 0;
}

.banner-link {
  color: #7f5a35;
  /* Color del enlace */
  font-weight: bold;
  text-decoration: none;
}

.banner-link:hover {
  text-decoration: underline;
  /* Subraya el enlace cuando se hace hover */
}

.image-section {
  width: 50%;
  margin-right: 20px;
}

.main-image {
  height: 30em;
  max-width: 35em;
  border-radius: 1em;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

.session-subtitle {
  font-size: 1.2rem;
  color: #777;
}

.session-price-range {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
}

/* Estilos para los botones de sesiones */
.session-buttons {
  margin: 20px 0;
}

.button-group {
  display: flex;
  gap: 10px;
  /* Espacio entre los botones */
}

.session-button {
  padding: 10px 20px;
  border: 2px solid #7f5a35;
  background-color: white;
  color: #7f5a35;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.session-button:hover {
  background-color: #e3c727;
  color: white;
}

.session-button.active {
  background-color: #7f5a35;
  color: white;
}

.session-button.active:hover {
  background-color: #e3c727;
  /* Cambia de color al hacer hover cuando esté seleccionado */
  color: white;
}

.promotion-text {
  font-size: 1.1rem;
  color: red;
}

.session-description {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
}

.session-select {
  margin: 20px 0;
}

.session-select label {
  margin-right: 10px;
  font-weight: bold;
}

.price-section {
  margin: 20px 0;
}

.buy-button {
  background-color: #7f5a35;
  /* Botón blanco */
  color: #fff;
  /* Letras negras */
  border: 1px solid #000;
  /* Borde negro */
  padding: 1em;
  width: 13em;
  text-align: center;
  cursor: pointer;
  margin-top: auto;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: #e3c727;
  /* Color café al hacer hover sobre el botón */
  color: #fff;
  /* Letras blancas al hacer hover sobre el botón */
  border: 1px solid #7f5a35;
  /* Borde café al hacer hover sobre el botón */
}

.additional-info {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  /* Limita el ancho máximo del contenedor */
  margin-left: auto;
  /* Alinea el contenedor al centro */
  margin-right: auto;
  /* Alinea el contenedor al centro */
  margin-bottom: 3em;
}

.details-section {
  border-top: 1px solid #e0e0e0;
  padding: 10px 0;
}

.details-section:first-of-type {
  border-top: none;
}

.details-section summary {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.details-section .open {
  color: #7f5a35;
}

.details-section div {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
}

.details-section summary::after {
  content: "▼";
  /* Flecha para indicar colapso */
  font-size: 0.8rem;
  margin-left: 0.5em;
  transition: transform 0.3s ease;
}

.details-section .open::after {
  content: "▲";
  transform: rotate(180deg);
}

.price-before {
  text-decoration: line-through;
  color: #888;
  font-size: 1.5rem;
  margin-right: 10px;
}

.price-now {
  font-weight: bold;
  color: #000;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .pack-container {
    margin-top: -3em;
  }

  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .image-section,
  .details-section {
    width: 100%;
  }

  h1 {
    font-size: 2rem;
  }

  .session-price-range,
  .price-section {
    font-size: 1.3rem;
  }

  .buy-button {
    font-size: 1rem;
    width: 100%;
  }

  .main-image {
    height: auto;
    max-width: 100%;
  }

  .additional-info {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
  }

  details summary {
    font-size: 1rem;
  }

  .banner {
    font-size: 0.9rem;
    margin-top: 4.25em;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .session-button {
    width: 100%;
    padding: 10px;
  }
}
</style>
