<template>
  <div class="pago-aprobado">
    <div class="card">
      <img src="../assets/bali_main.png" alt="Logo" class="logo" />
      <h1 class="titulo">¡Pago Aprobado! 🎉</h1>
      <p class="mensaje">
        Tu pago se ha procesado exitosamente. Gracias por tu compra.
      </p>
      <p class="notificacion">
        Se ha enviado una notificación de tu compra a tu correo.
      </p>
      <router-link to="/agendar-sesion" class="boton"
        >Ir a agendar tus sesiones</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PagoAprobado",
};
</script>

<style scoped>
.pago-aprobado {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #f9e8e2, #f1c5b0);
  font-family: "Arial", sans-serif;
}

.card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.logo {
  width: 10em;
}

.titulo {
  color: #e67e22;
  font-size: 24px;
  margin-bottom: 10px;
}

.mensaje {
  color: #555;
  font-size: 16px;
  margin-bottom: 20px;
}

.notificacion {
  color: #777;
  font-size: 14px;
  margin-bottom: 30px;
}

.boton {
  display: inline-block;
  padding: 10px 20px;
  background: #e67e22;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.boton:hover {
  background: #d35400;
}
</style>
