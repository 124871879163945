<template>
  <Navbar />
  <div class="checkout-wrapper">
    <div class="checkout-container">
      <div class="checkout-card">
        <h2>Tus Datos</h2>
        <form @submit.prevent="proceedToPayment" class="checkout-form">
          <!-- RUT Input -->
          <div class="form-group">
            <label for="rut">RUT</label>
            <input
              v-model="formattedRut"
              type="text"
              id="rut"
              @input="formatRut"
              @blur="checkUserByRut"
              placeholder="12.345.678-9"
              required
              class="input-text"
            />
          </div>

          <!-- Alerts -->
          <transition name="fade">
            <div v-if="userFound" class="alert alert-success">
              ¡Hola {{ guest.nombre }}! Qué bueno verte de nuevo 😊. Hemos
              recuperado tus datos para que no tengas que llenarlos.
            </div>
          </transition>
          <transition name="fade">
            <div v-if="userNotFound" class="alert alert-warning">
              No encontramos tus datos esta vez 😔. Si es tu primera compra, por
              favor completa tus datos para continuar.
            </div>
          </transition>

          <!-- Nombre -->
          <div class="form-group">
            <label for="nombre">Nombre</label>
            <input
              v-model="guest.nombre"
              type="text"
              id="nombre"
              placeholder="Tu nombre"
              required
              class="input-text"
              :disabled="!rutValidated"
            />
          </div>

          <!-- Apellido -->
          <div class="form-group">
            <label for="apellido">Apellido</label>
            <input
              v-model="guest.apellido"
              type="text"
              id="apellido"
              placeholder="Tu apellido"
              required
              class="input-text"
              :disabled="!rutValidated"
            />
          </div>

          <!-- Teléfono -->
          <div class="form-group">
            <label for="telefono">Teléfono</label>
            <input
              v-model="guest.telefono"
              type="tel"
              id="telefono"
              required
              placeholder="987654321"
              class="input-text"
              :disabled="!rutValidated"
            />
          </div>

          <!-- Email -->
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="guest.email"
              type="email"
              id="email"
              required
              placeholder="correo@correo.cl"
              class="input-text"
              :disabled="!rutValidated"
            />
          </div>

          <!-- Texto de aceptación -->
          <p class="acceptance-text">
            Al realizar el pago, aceptas nuestros
            <router-link to="/terminos-condiciones"
              >Términos y Condiciones</router-link
            >
            y nuestra
            <router-link to="/politica-privacidad"
              >Política de Privacidad</router-link
            >.
          </p>

          <!-- Submit Button -->
          <div class="form-group">
            <button
              type="submit"
              class="btn-primary"
              :disabled="loading || !rutValidated"
            >
              Ir a pagar
            </button>
          </div>
        </form>

        <div v-if="loading" class="loading-indicator">Procesando...</div>
      </div>

      <!-- Resumen del Carrito -->
      <div class="checkout-card">
        <h2>Resumen del Carrito</h2>
        <div v-if="!carrito || carrito.length === 0">El carrito está vacío</div>
        <div v-else>
          <div v-for="item in carrito" :key="item.id" class="cart-item">
            <img
              :src="item.imagen"
              alt="Imagen del producto"
              class="item-image"
            />
            <div class="item-details">
              <h3>{{ item.nombre }}</h3>
              <p>{{ formatPrice(item.precio) }}</p>
              <p>Cantidad: {{ item.cantidad }}</p>
            </div>
          </div>
          <div class="cart-total">
            <h3>Total: {{ formatPrice(total) }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import apiClient from "../api";
import Navbar from "../components/NavbarTop.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "AuthCheckout",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const userFound = ref(false);
    const userNotFound = ref(false);
    const rutValidated = ref(false); // Para habilitar los campos después de validar RUT

    const guest = ref({
      rut: "",
      nombre: "",
      apellido: "",
      email: "",
      telefono: "",
    });

    const formattedRut = ref("");

    const carrito = computed(() => store.state.carrito);
    const total = computed(() => {
      return carrito.value.reduce(
        (acc, item) => acc + item.precio * item.cantidad,
        0
      );
    });

    const formatPrice = (value) => {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    };

    const formatRut = () => {
      let rut = formattedRut.value.replace(/\./g, "").replace(/-/g, "");
      guest.value.rut = rut;

      if (rut.length > 1) {
        const cuerpo = rut.slice(0, -1);
        const verificador = rut.slice(-1);
        formattedRut.value =
          cuerpo.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "-" + verificador;
      }
    };

    const checkUserByRut = async () => {
      if (!guest.value.rut) return;

      loading.value = true;
      userFound.value = false;
      userNotFound.value = false;

      try {
        const response = await apiClient.get(`/auth/rut/${guest.value.rut}`);
        if (response.data) {
          guest.value.nombre = response.data.nombre;
          guest.value.apellido = response.data.apellido;
          guest.value.email = response.data.email;
          guest.value.telefono = response.data.telefono;
          userFound.value = true;
        } else {
          userNotFound.value = true;
        }
      } catch (error) {
        userNotFound.value = true;
      } finally {
        rutValidated.value = true; // Se activa independientemente de si hay datos o no
        loading.value = false;
      }
    };

    const proceedToPayment = async () => {
      loading.value = true;
      const reference = "REF-" + Date.now();
      const detalles = carrito.value.map((item) => ({
        id_producto: item.id,
        cantidad: item.cantidad,
        precio_unitario: item.precio,
      }));

      try {
        const response = await apiClient.post("/payment/create-session", {
          amount: total.value,
          reference,
          carrito: detalles,
          cliente: guest.value,
        });

        if (response.data.processUrl) {
          window.location.href = response.data.processUrl;
        } else {
          alert("Error creando la venta");
        }
      } catch (error) {
        alert("Error en la transacción");
      } finally {
        loading.value = false;
      }
    };

    return {
      guest,
      formattedRut,
      carrito,
      total,
      userFound,
      userNotFound,
      rutValidated,
      formatPrice,
      formatRut,
      checkUserByRut,
      proceedToPayment,
      loading,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.checkout-wrapper {
  display: flex;
  justify-content: center;
  padding: 30px; /* Aumenté el padding para mayor espacio */
  margin-top: 5em;
  background-color: #ffffff; /* Color de fondo más suave */
}

.checkout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px; /* Mayor separación entre columnas */
  width: 85%; /* Ajuste para que no sea tan ancho */
  max-width: 1100px;
  margin: 40px auto;
}

.checkout-card {
  background-color: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.checkout-card:hover {
  transform: translateY(-5px); /* Añade un pequeño hover para animar */
}

.checkout-form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative; /* Esto es clave para posicionar las alertas dentro del contenedor */
}

input.input-text {
  padding: 14px;
  margin-top: 8px;
  border: 1.5px solid #dcdcdc;
  border-radius: 6px;
  font-size: 1rem;
}

input.input-text:focus {
  border-color: #3498db;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

button.btn-primary {
  background-color: #3498db;
  color: white;
  padding: 14px 18px;
  border-radius: 8px;
  font-size: 1.1em;
  transition: background-color 0.3s ease;
}

button.btn-primary:hover {
  background-color: #2980b9;
}

.alert {
  padding: 15px;
  border-radius: 6px;
  text-align: center;
}

.alert-success {
  background-color: #e0f7e9;
  color: #28a745;
}

.alert-warning {
  background-color: #fff6cc;
  color: #8a6d3b;
}

.loading-indicator {
  text-align: center;
  font-size: 1.2em;
  grid-column: span 2;
}

.acceptance-text {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
  text-align: center;
}

.acceptance-text a {
  color: #3498db;
  text-decoration: none;
}

.acceptance-text a:hover {
  text-decoration: underline;
}

/* Adaptación para pantallas móviles */
@media (max-width: 1024px) {
  .checkout-wrapper {
    margin-top: 2.25em;
    padding: 0em;
  }

  .checkout-container {
    grid-template-columns: 1fr;
    width: 90%;
  }

  button.btn-primary {
    grid-column: span 1;
  }
}
</style>
