<template>
  <div>
    <Navbar @open-carrito="toggleCarrito" />
    <Carrito v-if="mostrarCarrito" @close="toggleCarrito" />

    <!-- Modal con Imagen Estática -->
    <!--
    <div v-if="mostrarModal" class="modal-overlay">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>

        <img src="../assets/promo.jpeg" alt="Promoción" class="modal-image" />

        <button class="modal-close-button" @click="closeModal">Cerrar</button>
      </div>
    </div>
     -->

    <div>
      <main class="main-content">
        <section class="banner">
          <div class="banner-content">
            <h1>Inspirado en Bali</h1>
            <h2>Hecho en Chile</h2>
          </div>
        </section>
        <section class="lineaCafe">
          <div class="marquee">
            <div class="marquee-content">
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
              <span
                >Ya Abrimos! - Los Ángeles - Pueblito Siglo XXI - Bali
                Solarium</span
              >
            </div>
          </div>
        </section>
        <section class="sesiones-section">
          <Sesiones @agregar-al-carrito="agregarAlCarrito" />
        </section>
        <section class="productos-section">
          <Productos @agregar-al-carrito="agregarAlCarrito" />
        </section>
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>
import Productos from "../components/ProductosPage.vue";
import Sesiones from "../components/Sesiones.vue";
import Navbar from "../components/NavbarTop.vue";
import Carrito from "../components/Carrito.vue";
import Footer from "../components/Footer.vue";
import state from "../state";

export default {
  components: {
    Productos,
    Sesiones,
    Navbar,
    Carrito,
    Footer,
  },
  data() {
    return {
      mostrarCarrito: false,
      mostrarModal: true, // Mostrar modal al cargar la página
      form: {
        nombre: "",
        apellido: "",
        rut: "",
        rutSinFormato: "",
        email: "",
        telefono: "",
      },
      step: 1, // Step actual del modal, empieza en 1 (formulario)
      isSubmitting: false,
    };
  },
  methods: {
    toggleCarrito() {
      this.mostrarCarrito = !this.mostrarCarrito;
    },
    agregarAlCarrito(producto) {
      state.agregarAlCarrito(producto);
    },
    closeModal() {
      this.mostrarModal = false;
    },
  },
};
</script>

<style scoped>
.main-content {
  margin-top: 3.5em;
  padding-top: 1em;
  text-align: center;
  flex: 1;
  width: 100%;
}

.banner {
  background-image: url("../assets/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  width: 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.banner h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.banner h2 {
  font-size: 1.8rem;
  margin: 0;
}

.lineaCafe {
  height: 3em;
  background-color: #7f5a35;
  color: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.marquee {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.marquee-content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  animation: marquee 60s linear infinite;
  width: 100%;
}

.marquee-content span {
  display: inline-block;
  padding-right: 2rem;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.productos-section {
  margin-top: 2rem;
  width: 100%;
}

.sesiones-section {
  width: 100%;
  margin-top: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  padding: 0;
  position: relative;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-image {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.8rem;
  color: #ff5f5f;
  background: white;
  border: 2px solid #ff5f5f;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close:hover {
  background: #ff5f5f;
  color: white;
}

.modal-close-button {
  background-color: #7f5a35;
  width: 90%;
  /* Marrón */
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.1em;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #e3c727;
  /* Amarillo suave */
}
</style>
