<template>
  <div class="pago-rechazado">
    <div class="card">
      <img src="../assets/bali_main.png" alt="Error" class="icono" />
      <h1 class="titulo">Pago Rechazado ❌</h1>
      <p class="mensaje">Lo sentimos, tu pago no pudo ser procesado.</p>
      <p class="instrucciones">
        Por favor, verifica tus datos e intenta nuevamente.
      </p>
      <router-link to="/" class="boton">Volver al Inicio</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PagoRechazado",
};
</script>

<style scoped>
.pago-rechazado {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #fdecea, #f9d3cf);
  font-family: "Arial", sans-serif;
}

.card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.icono {
  width: 8em;
  margin-bottom: 20px;
}

.titulo {
  color: #e74c3c;
  font-size: 24px;
  margin-bottom: 10px;
}

.mensaje {
  color: #555;
  font-size: 16px;
  margin-bottom: 10px;
}

.instrucciones {
  color: #777;
  font-size: 14px;
  margin-bottom: 30px;
}

.boton {
  display: inline-block;
  padding: 10px 20px;
  background: #e74c3c;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.boton:hover {
  background: #c0392b;
}
</style>
