<template>
    <Navbar />
    <div class="cliente-perfil">
        <h1>Buscar Cliente</h1>
        <div class="search-bar">
            <input v-model="searchQuery" type="text" placeholder="Buscar por RUT o nombre" />
            <button @click="buscarCliente">Buscar</button>
        </div>

        <div v-if="loading" class="loading">Cargando...</div>

        <!-- Múltiples Clientes -->
        <div v-if="clientes && clientes.length > 0" class="clientes-lista">
            <h2>Clientes Encontrados</h2>
            <ul>
                <li v-for="cliente in clientes" :key="cliente.rut">
                    {{ cliente.nombre }} {{ cliente.apellido }} - {{ cliente.rut }} - {{ cliente.email }}
                    <button @click="seleccionarCliente(cliente)">Seleccionar</button>
                </li>
            </ul>
        </div>

        <!-- Detalles del Cliente Seleccionado -->
        <div v-else-if="cliente" class="cliente-detalles">
            <h2>Perfil del Cliente</h2>
            <p><b>Nombre:</b> {{ cliente.nombre }} {{ cliente.apellido }}</p>
            <p><b>Email:</b> {{ cliente.email }}</p>
            <p><b>Teléfono:</b> {{ cliente.telefono }}</p>
            <p><b>Sesiones Totales:</b> {{ comprasSesiones.total_sesiones }}</p>
            <p><b>Sesiones Restantes:</b> {{ comprasSesiones.sesiones_restantes }}</p>

            <!-- Sesiones Pasadas -->
            <h3>Sesiones Pasadas</h3>
            <table v-if="sesionesPasadas.length > 0">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Servicio</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sesion in sesionesPasadas" :key="sesion.id">
                        <td>{{ formatFecha(sesion.fecha_sesion) }}</td>
                        <td>{{ sesion.hora_inicio }}</td>
                        <td>{{ sesion.service }}</td>
                        <td>{{ sesion.status }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-else>No hay sesiones pasadas.</p>

            <!-- Sesiones Futuras -->
            <h3>Sesiones Futuras</h3>
            <table v-if="sesionesFuturas.length > 0">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Hora</th>
                        <th>Servicio</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="sesion in sesionesFuturas" :key="sesion.id">
                        <td>{{ formatFecha(sesion.fecha_sesion) }}</td>
                        <td>{{ sesion.hora_inicio }}</td>
                        <td>{{ sesion.service }}</td>
                        <td>{{ formatStatus(sesion.status) }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-else>No hay sesiones futuras.</p>

            <!-- Compras -->
            <h3>Compras</h3>
            <table v-if="compras.length > 0">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Total</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="compra in compras" :key="compra.id">
                        <td>{{ formatFecha(compra.fecha_venta) }}</td>
                        <td>${{ compra.total }}</td>
                        <td>{{ formatStatus(compra.status) }}</td>
                    </tr>
                </tbody>
            </table>
            <p v-else>No hay compras registradas.</p>
        </div>

        <!-- No Resultados -->
        <div v-else-if="searchQuery">
            <p>No se encontró ningún cliente con esa información.</p>
        </div>
    </div>
</template>


<script>
import apiClient from "@/api";
import Navbar from "@/components/AdminNavbar.vue";

export default {
    name: "AdminSearchClient",
    components: {
        Navbar,
    },
    data() {
        return {
            searchQuery: "", // Campo de búsqueda
            cliente: null, // Datos del cliente
            clientes: [], // Lista de clientes encontrados
            comprasSesiones: {}, // Información de sesiones
            sesionesPasadas: [], // Historial de sesiones pasadas
            sesionesFuturas: [], // Historial de sesiones futuras
            compras: [], // Historial de compras
            loading: false, // Estado de carga
        };
    },
    methods: {
        async buscarCliente() {
            if (!this.searchQuery) {
                alert("Por favor, ingresa un RUT o nombre para buscar.");
                return;
            }

            this.loading = true;
            this.cliente = null;
            this.clientes = [];
            this.comprasSesiones = {};
            this.sesionesPasadas = [];
            this.sesionesFuturas = [];
            this.compras = [];

            try {
                const response = await apiClient.get(`/auth/buscar`, {
                    params: { query: this.searchQuery },
                });

                if (response.data.clientes && response.data.clientes.length > 1) {
                    this.clientes = response.data.clientes; // Múltiples clientes encontrados
                } else if (response.data.cliente) {
                    this.cliente = response.data.cliente;
                    this.comprasSesiones = response.data.comprasSesiones;
                    this.sesionesPasadas = response.data.sesionesPasadas;
                    this.sesionesFuturas = response.data.sesionesFuturas;
                    this.compras = response.data.compras;
                } else {
                    alert("No se encontraron resultados.");
                }
            } catch (error) {
                console.error("Error al buscar cliente:", error);
                alert("Hubo un error al buscar el cliente.");
            } finally {
                this.loading = false;
            }
        },
        seleccionarCliente(cliente) {
            this.searchQuery = cliente.rut; // Actualiza el campo de búsqueda con el RUT
            this.buscarCliente(); // Realiza la búsqueda
        },
        formatFecha(fecha) {
            const date = new Date(fecha);
            return date.toLocaleDateString("es-ES", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
        },
        formatStatus(status) {
            const statuses = {
                0: "Procesándotelos",
                1: "Completado",
                2: "Rechazado",
                10: "Venta Manual",
            };
            return statuses[status] || "Desconocido";
        },
    },
};
</script>


<style scoped>
.cliente-perfil {
    max-width: 900px;
    margin: auto;
    margin-top: 4em;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-bar input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}


.search-bar button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.search-bar button:hover {
    background-color: #0056b3;
}

.loading {
    text-align: center;
    font-size: 1.2em;
    color: #666;
}

.clientes-lista ul {
    list-style: none;
    padding: 0;
}

.clientes-lista li {
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.clientes-lista button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.clientes-lista li button:hover {
    background-color: #218838;
}

.info-cliente p {
    margin: 5px 0;
}

.sesiones {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.sesiones div {
    flex: 1;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table th,
table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

table th {
    background-color: #f4f4f4;
}

table td {
    background-color: #fff;
}

@media (max-width: 768px) {
    .sesiones {
        flex-direction: column;
    }

    .search-bar {
        flex-direction: column;
    }

    .search-bar button {
        width: 100%;
    }
}
</style>
